export const TITLE_SECTION = {
  SPORT: 'Trận cầu siêu nóng',
  GAME: 'Quay số',
  TOP_PLAY: 'Nhiều người chơi'
}

export const EMPTYDATA = {
  text: 'Dữ liệu đang cập nhật',
  subText: 'Quý khách vui lòng quay lại sau'
}
